import { memo, useMemo, useRef, useState } from "react";
import { format } from "date-fns";
import ReCAPTCHA from "react-google-recaptcha";
import SignatureCanvas from "react-signature-canvas";
import clsx from "clsx";

const Checkbox = memo(({ label, ...props }) => (
  <div className="checkbox-container">
    <label className="checkbox-label">
      <input {...props} type="checkbox" className="checkbox-input" />
      <div className="checkbox-box" />
      <p className="checkbox-text">{label}</p>
    </label>
  </div>
));

Checkbox.displayName = "Checkbox";

const App = () => {
  const [values, setValues] = useState({
    company: "",
    siret: "",
    full_name: "",
    function: "",
    signature: "",
    recaptcha: "",
    options: ["data-analyst"],
    data_analyst_total: "",
  });
  const [errors, setErrors] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const date = useMemo(() => format(new Date(), "dd/MM/yyyy"), []);
  const signatureRef = useRef(null);
  const recaptchaRef = useRef(null);

  const onCheck = (event, value) => {
    const newValue = [...values.options];

    if (event.target.checked) {
      newValue.push(value);

      return setValues((rest) => ({ ...rest, options: newValue }));
    } else {
      const index = newValue.indexOf(value);

      if (index !== -1) {
        newValue.splice(index, 1);
      }

      return setValues((rest) => ({ ...rest, options: newValue }));
    }
  };

  const onChange = (key, value) => {
    return setValues((rest) => ({ ...rest, [key]: value }));
  };

  const onSign = () => {
    const signature = signatureRef?.current
      ?.getTrimmedCanvas()
      .toDataURL("image/png");

    return onChange("signature", signature);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setIsProcessing(true);

    if (values?.success) {
      setValues((rest) => ({ ...rest, success: false }));
    }

    fetch(`${process.env.REACT_APP_API_ENDPOINT}/recruitment/data-analyst`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values, agency: values?.agency?.city || null }),
    })
      .then((res) => res.json())
      .then(({ status, message, data }) => {
        if (status === 200) {
          signatureRef.current.clear();

          setValues({
            company: "",
            siret: "",
            full_name: "",
            function: "",
            signature: "",
            recaptcha: "",
            options: ["data-analyst"],
            data_analyst_total: "",
            success: true,
          });

          setErrors({});
        } else {
          setErrors(data);
        }
      })
      .finally(() => {
        recaptchaRef.current.reset();
        setIsProcessing(false);
      });
  };

  return (
    <main className="container">
      <div className="image-container" />
      <h1 className="title">ATTESTATION DE SOUTIEN ENTREPRISE</h1>
      <form noValidate onSubmit={onSubmit} className="form">
        <p className="description">
          Dans le cadre d’une étude d’identification des besoins en recrutement
          à horizon fin 2023, j’atteste par la présente que la mise en place
          d’une <b>Préparation Opérationnelle à l’Emploi</b> en partenariat avec
          Pôle Emploi, l’OPCO Atlas et les organismes de formation Arinfo &
          Simplon, avec pour lieu de formation <b>La Roche sur Yon</b> répond à
          un réel besoin sur notre bassin économique pour la thématique suivante
          :
        </p>
        <div className="form-field">
          <Checkbox
            label={
              <>
                <b>Data Analyst</b>{" "}
                <i>
                  (voir{" "}
                  <a
                    href="/fichiers/3_Data_analyst-Programme_de_formation.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    programme
                  </a>{" "}
                  annexé)
                </i>
              </>
            }
            name="data-analyst"
            checked={values.options.includes("data-analyst")}
            onChange={(event) => {}}
            disabled={isProcessing}
          />
          <p className="description">
            Compétences : qualification des données, préparation pour les algo
            IA, conception programme IA à l’aide du machine learning,
            réalisation de rendus visuels, mise à disposition contrôlée, analyse
            de demande et exploitation des bases de données.
          </p>
          {values.options.includes("data-analyst") && (
            <div className="form-row">
              <div className="form-field">
                <label className="form-label">
                  Nombre de recrutements envisagés
                </label>
                <input
                  name="data_analyst_total"
                  value={values?.data_analyst_total}
                  onChange={(event) => {
                    onChange(
                      "data_analyst_total",
                      !event.target.value.startsWith("0")
                        ? event.target.value.replace(/[^0-9]/g, "")
                        : ""
                    );
                  }}
                  disabled={isProcessing}
                  className={clsx("form-input", {
                    error: errors?.data_analyst_total,
                  })}
                />
                {errors?.data_analyst_total && (
                  <small className="form-error">
                    {errors?.data_analyst_total}
                  </small>
                )}
              </div>
              <div className="form-field form-field-filler" />
            </div>
          )}
          <p style={{ marginBottom: 5 }}>
            Les missions du Data Analyst sont variées :
          </p>
          <ul className="list">
            <li>
              <b>Recueil et extraction des sources de données</b> (produits,
              clients, internes, temps de production, réseaux sociaux, ...)
              pertinentes et de qualité qu’il traduit ensuite en données
              statistiques
            </li>
            <li>
              <b>Création de dashboards</b>, mise en place de KPIs et reporting
              des performances pour donner une vision cohérente des résultats
              aux différentes équipes
            </li>
            <li>
              <b>Mise en place de process</b>/requêtes et automatisation
            </li>
            <li>
              <b>Production d’analyses</b> métiers et de recommandations aux
              managers
            </li>
            <li>
              <b>Gestion des outils d’analyses</b> pour que les décideurs
              internes et les clients puissent suivre l’évolution de leurs
              produits
            </li>
            <li>
              <b>Veille technologique</b> des nouveaux outils visant à
              l’améliorer l’analyse des données
            </li>
            <li>
              <b>Traitement, exploitation et intégration des données</b> dans un
              data warehouse (entrepôt de données)
            </li>
            <li>
              <b>Rendus visuels</b> des analyses
            </li>
          </ul>
        </div>
        <p className="description">
          Cette action de formation pourra nous permettre de sélectionner des
          candidats formés et opérationnels immédiatement.
        </p>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom entreprise :</label>
            <input
              name="company"
              value={values.company}
              onChange={(event) => onChange("company", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.company })}
            />
            {errors?.company && (
              <small className="form-error">{errors?.company}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">N° SIRET :</label>
            <input
              name="siret"
              value={values.siret}
              onChange={(event) => onChange("siret", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.siret })}
            />
            {errors?.siret && (
              <small className="form-error">{errors?.siret}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label className="form-label">Nom et prénom du signataire :</label>
            <input
              name="full_name"
              value={values.full_name}
              onChange={(event) => onChange("full_name", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.full_name })}
            />
            {errors?.full_name && (
              <small className="form-error">{errors?.full_name}</small>
            )}
          </div>
          <div className="form-field">
            <label className="form-label">Fonction du signataire :</label>
            <input
              name="function"
              value={values.function}
              onChange={(event) => onChange("function", event.target.value)}
              disabled={isProcessing}
              className={clsx("form-input", { error: errors?.function })}
            />
            {errors?.function && (
              <small className="form-error">{errors?.function}</small>
            )}
          </div>
        </div>
        <div className="form-row">
          <div className="form-field">
            <label>Signature</label>
            <div className="form-signature">
              <SignatureCanvas
                ref={signatureRef}
                backgroundColor="white"
                onEnd={onSign}
                canvasProps={{
                  disabled: true,
                  style: {
                    pointerEvents: isProcessing && "none",
                    width: "100%",
                    height: "100%",
                    border: "1px solid #000",
                    borderRadius: "4px",
                    borderColor: isProcessing
                      ? "hsl(0, 0%, 90%)"
                      : errors?.signature
                      ? "#ff3548"
                      : "rgb(204, 204, 204)",
                  },
                }}
              />
            </div>
            {errors?.signature && (
              <small className="form-error">{errors?.signature}</small>
            )}
          </div>
          <div className="form-field form-field-filler" />
        </div>
        <div className="form-field">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={(response) => onChange("recaptcha", response)}
          />
          {errors?.recaptcha && (
            <small className="form-error">{errors?.recaptcha}</small>
          )}
        </div>
        {values?.success && (
          <div className="form-success">Attestation de soutien envoyée</div>
        )}
        <div className="form-date">Fait le {date}</div>
        <p
          className="description"
          style={{ marginTop: 10, textAlign: "center" }}
        >
          Ce formulaire ne vous engage en aucun cas à embaucher, il s’agit d’une
          simple identification des besoins futurs. Merci pour votre
          participation !
        </p>
        <button type="submit" disabled={isProcessing} className="form-button">
          {isProcessing ? "Chargement" : "Envoyer"}
        </button>
        <hr style={{ borderColor: "#f5f5fb", marginTop: 20 }} />
        <p className="form-rgpd">
          Les données recueillies par ce formulaire ont pour unique but de mener
          l'étude d’identification des besoins en recrutement à horizon fin 2023
          et ne seront pas réutilisées.
        </p>
        <div className="links">
          <a
            href="https://arinfo.fr/mentions-legales"
            target="_blank"
            rel="nopenner noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://arinfo.fr/conditions-generales-de-prestation-et-de-vente"
            target="_blank"
            rel="nopenner noreferrer"
          >
            CGPV
          </a>
        </div>
      </form>
    </main>
  );
};

export default App;
